/*-----------------------------------------------------------------------------------

   Template Name: Quickai
   Template URI: http://demo.harnishdesign.net/html/quickai
   Description: Recharge & Bill Payment, Booking HTML Template
   Author: Harnish Design
   Author URI: https://themeforest.net/user/harnishdesign
   Version: 1.1
	
=================================================
  Table of Contents
=================================================

	- Basic
	- Helpers Classes
	- Layouts
	- Header
		- Logo
		- Primary Menu
		- Secondary Nav
		- Page Header
	- Recharge & Bill Pages
	- Booking Pages
	- Elements
		- Featured Box
		- Team
		- Accordion & Toggle
		- Tabs
		- Easy Responsive Tab Accordion
		- Custom Background
		- Brands Grid
		- Banner
		- Simple ul
	- Footer
		- Payments Images
		- Newsleter
		- Social Icons
		- Login/Signup Modal Dialog
		- Back to Top
	- Extra

-------------------------------------------------------------------*/
/* =================================== */
/*  Basic Style 
/* =================================== */
body {
    background: #e7e9ed;
    color: #535b61;
    font-family: "Poppins", sans-serif;

    line-height: 22px;
}

form {
    padding: 0;
    margin: 0;
    display: inline;
}

a {
    color: #ffffff !important;
}

img {
    vertical-align: inherit;
}

a, a:focus {
    color: #2f5471;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

    a:hover, a:active {
        color: #0c2f55;
        text-decoration: none;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

.btn-primary {
    border-color: #1f3156;
}

a:focus, a:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus,
button:active {
    outline: none;
}

p {
    line-height: 1.9;
}

blockquote {
    border-left: 5px solid #eee;
    padding: 10px 20px;
}

iframe {
    border: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
    color: #0c2f54;
    font-family: "Poppins", sans-serif;
}

.lead {
    font-size: 1.3em;
    line-height: 1.8;
}

/*-------- Preloader --------*/
#preloader {
    position: fixed;
    z-index: 999999999 !important;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

    #preloader [data-loader="dual-ring"] {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        margin-left: -25px;
        margin-top: -25px;
        border-radius: 50%;
        border: 5px solid #2f5471;
        border-color: #2f5471;
        animation: dual-ring 1.2s linear infinite;
    }

@keyframes dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* =================================== */
/*  Helpers Classes
/* =================================== */
/* Box Shadow */
.shadow-md {
    box-shadow: 0px 0px 50px -62px rgba(0, 0, 0, 0.4);
}

/* Border Radius */
.rounded-top-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.rounded-left-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.rounded-right-0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

/* Text Size */
.text-0 {
    font-size: 11px !important;
    font-size: 0.6875rem !important;
}

.text-1 {
    font-size: 12px !important;
    font-size: 0.75rem !important;
}

.text-2 {
    font-size: 14px !important;
    font-size: 0.875rem !important;
}

.text-3 {
    font-size: 16px !important;
    font-size: 1rem !important;
}

.text-4 {
    font-size: 18px !important;
    font-size: 1.125rem !important;
}

.text-5 {
    font-size: 21px !important;
    font-size: 1.3125rem !important;
}

.text-6 {
    font-size: 24px !important;
    font-size: 1.50rem !important;
}

.text-7 {
    font-size: 28px !important;
    font-size: 1.75rem !important;
}

.text-8 {
    font-size: 32px !important;
    font-size: 2rem !important;
}

.text-9 {
    font-size: 36px !important;
    font-size: 2.25rem !important;
}

.text-10 {
    font-size: 40px !important;
    font-size: 2.50rem !important;
}

.text-11 {
    font-size: 44px !important;
    font-size: 2.75rem !important;
}

.text-12 {
    font-size: 48px !important;
    font-size: 3rem !important;
}

.text-13 {
    font-size: 52px !important;
    font-size: 3.25rem !important;
}

.text-14 {
    font-size: 56px !important;
    font-size: 3.50rem !important;
}

.text-15 {
    font-size: 60px !important;
    font-size: 3.75rem !important;
}

.text-16 {
    font-size: 64px !important;
    font-size: 4rem !important;
}

.text-17 {
    font-size: 72px !important;
    font-size: 4.5rem !important;
}

.text-18 {
    font-size: 80px !important;
    font-size: 5rem !important;
}

.text-19 {
    font-size: 84px !important;
    font-size: 5.25rem !important;
}

.text-20 {
    font-size: 92px !important;
    font-size: 5.75rem !important;
}

/* Line height */
.line-height-07 {
    line-height: 0.7 !important;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-2 {
    line-height: 1.2 !important;
}

.line-height-3 {
    line-height: 1.4 !important;
}

.line-height-4 {
    line-height: 1.6 !important;
}

.line-height-5 {
    line-height: 1.8 !important;
}

/* Font Weight */
.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

/* Opacity */
.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

.opacity-10 {
    opacity: 1;
}

/* Background light */
.bg-light {
    background-color: #FFF !important;
}

.bg-light-1 {
    background-color: #f9f9fb !important;
}

.bg-light-2 {
    background-color: #f8f8fa !important;
}

.bg-light-3 {
    background-color: #f5f5f5 !important;
}

.bg-light-4 {
    background-color: #eff0f2 !important;
}

.bg-light-5 {
    background-color: #ececec !important;
}

/* Background Dark */
.bg-dark {
    background-color: #111418 !important;
}

.bg-dark-1 {
    background-color: #191f24 !important;
}

.bg-dark-2 {
    background-color: #232a31 !important;
}

.bg-dark-3 {
    background-color: #2b343c !important;
}

.bg-dark-4 {
    background-color: #38434f !important;
}

.bg-dark-5 {
    background-color: #435161 !important;
}

.error {
    border: 1px solid #ff3b4e !important;
}

/* =================================== */
/*  Layouts
/* =================================== */
#main-wrapper {
    background: #f6f7f8;
}

    #main-wrapper.boxed {
        max-width: 1200px;
        margin: 0 auto;
        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

.section {
    position: relative;
    padding: 50px 0;
    padding: 3.125rem 0;
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1170px !important;
    }
}
/* =================================== */
/*  Header
/* =================================== */
#header {
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

    #header .navbar {
        padding: 0px;
    }

    #header.bg-transparent {
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: none;
    }

    #header.header-border .header-row {
        border-bottom: 1px solid rgba(250, 250, 250, 0.3);
    }

    #header .logo {
        position: relative;
        float: left;
        margin-right: 15px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: stretch;
        align-self: stretch;
    }

    #header .header-row {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        max-height: 100%;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-item-align: stretch;
        align-self: stretch;
    }

    #header .header-column {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

        #header .header-column .header-row {
            -webkit-box-pack: inherit;
            -ms-flex-pack: inherit;
            justify-content: inherit;
        }

.navbar-light .navbar-nav .active > .nav-link {
    color: #0c2f55;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show {
    color: #0c2f55;
}

.navbar-light .navbar-nav .show > .nav-link {
    color: #0c2f55;
}

.primary-menu {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    height: auto !important;
    -webkit-box-ordinal-group: 0;
    -ms-flex-item-align: stretch;
    align-self: stretch;
}

    .primary-menu.navbar {
        position: inherit;
    }

    .primary-menu ul.navbar-nav > li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
    }

        .primary-menu ul.navbar-nav > li a {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

.navbar-nav {
    font-size: 16px !important;
}

.primary-menu ul.navbar-nav > li > a {
    height: 70px;
    padding-left: 0.85em;
    padding-right: 0.85em;
    color: #fff;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;
    color: #2f5471 !important;
    font-weight: bold;
}

.primary-menu ul.navbar-nav > li:hover > a, .primary-menu ul.navbar-nav > li.active > a {
    color: #2f5471;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
    padding: 5px 0px;
    background-color: transparent;
    color: #777;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a:not(.btn) {
    color: #2f5471;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.primary-menu ul.navbar-nav > li.dropdown:hover > a:after {
    clear: both;
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent #ccc transparent;
    position: absolute;
    border-width: 0px 7px 6px 7px;
    bottom: 0px;
    left: 50%;
    margin: 0 0 0 -5px;
    z-index: 1009;
}

.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu {
    -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
    border: 0px none;
    border-top: 3px solid #ccc;
    padding: 10px 15px;
    min-width: 230px;
    margin: 0;
    font-size: 13px;
    font-size: 0.8125rem;
    z-index: 1021;
}

.primary-menu .dropdown-menu-right {
    left: auto !important;
    right: 100% !important;
}

.primary-menu ul.navbar-nav > li.dropdown-mega {
    position: static;
}

    .primary-menu ul.navbar-nav > li.dropdown-mega > .dropdown-menu {
        width: 100%;
        padding: 20px 20px;
        margin-left: 0px !important;
    }

    .primary-menu ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
        border-right: 1px solid #eee;
        padding: 10px 5px 10px 30px;
    }

        .primary-menu ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div:last-child {
            border-right: 0;
        }

    .primary-menu ul.navbar-nav > li.dropdown-mega .sub-title {
        display: block;
        font-size: 15px;
        margin-top: 1rem;
        padding-bottom: 5px;
    }

    .primary-menu ul.navbar-nav > li.dropdown-mega .dropdown-mega-submenu {
        list-style-type: none;
        padding-left: 0px;
    }

.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    left: 100%;
    margin-top: -40px;
}

.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-toggle:after {
    border-top: .4em solid transparent;
    border-right: 0;
    border-bottom: 0.4em solid transparent;
    border-left: 0.4em solid;
}

.primary-menu ul.navbar-nav > li.dropdown .dropdown-toggle .arrow {
    position: absolute;
    min-width: 30px;
    height: 100%;
    right: 0px;
    top: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

    .primary-menu ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        border-color: #000;
        border-top: 1px solid;
        border-right: 1px solid;
        width: 6px;
        height: 6px;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
    }

.primary-menu .dropdown-toggle:after {
    content: none;
}

.primary-menu ul.navbar-nav > li.login-signup > a {
    position: relative;
}

    .primary-menu ul.navbar-nav > li.login-signup > a:before {
        content: '';
        position: absolute;
        display: block;
        border-left: 1px solid #d6dde4;
        height: 24px;
        left: 0;
        top: 50%;
        -webkit-transform: translate3d(0, -50%, 0);
        transform: translate3d(0, -50%, 0);
    }

    .primary-menu ul.navbar-nav > li.login-signup > a span {
        background: #8f9dac;
        color: #fff;
        border-radius: 100%;
        width: 34px;
        height: 34px;
        vertical-align: middle;
        line-height: 34px;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
        margin-left: 0.4rem;
    }

.header-text-light .navbar-toggler span {
    background: #fff;
}

@media(min-width: 576px) {
    .PAD {
        margin-top: 105px;
    }
}

@media (min-width: 992px) {
    .header-text-light .primary-menu ul.navbar-nav > li > a {
        color: rgba(250, 250, 250, 0.8);
    }

    .header-text-light .primary-menu ul.navbar-nav > li.login-signup > a span {
        background: rgba(250, 250, 250, 0.4);
    }

    .header-text-light .primary-menu ul.navbar-nav > li.login-signup > a:before {
        border-color: rgba(250, 250, 250, 0.35);
    }

    .header-text-light .primary-menu ul.navbar-nav > li:hover > a, .header-text-light .primary-menu ul.navbar-nav > li.active > a {
        color: #fff;
    }
}

.primary-menu.nav-dark-dropdown ul.navbar-nav > li.dropdown .dropdown-menu {
    background-color: #252A2C;
    color: #fff;
    border-color: #252A2C;
}

    .primary-menu.nav-dark-dropdown ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
        background-color: #272c2e;
    }

.primary-menu.nav-dark-dropdown ul.navbar-nav > li.dropdown:hover > a:after {
    border-color: transparent transparent #252A2C transparent;
}

.primary-menu.nav-dark-dropdown ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
    color: #a3a2a2;
}

.primary-menu.nav-dark-dropdown ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a:not(.btn) {
    color: #fff;
}

.primary-menu.nav-dark-dropdown ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
    border-color: #3a3a3a;
}

.primary-menu.nav-primary-dropdown ul.navbar-nav > li.dropdown .dropdown-menu {
    background: #2f5471;
    color: #fff;
    border-color: #2f5471;
}

    .primary-menu.nav-primary-dropdown ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
        background: #2f5471;
    }

.primary-menu.nav-primary-dropdown ul.navbar-nav > li.dropdown:hover > a:after {
    border-color: #2f5471;
}

.primary-menu.nav-primary-dropdown ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
    color: rgba(250, 250, 250, 0.8);
}

.primary-menu.nav-primary-dropdown ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a:not(.btn) {
    color: #fff;
}

.primary-menu.nav-primary-dropdown ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
    border-color: rgba(250, 250, 250, 0.2);
}

@media (max-width: 991px) {
    #header .nav-dark-dropdown.primary-menu:before, .primary-menu.nav-dark-dropdown ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
        background-color: #252A2C;
    }

    #header .nav-primary-dropdown.primary-menu:before {
        background: #2f5471;
    }

    .primary-menu.nav-primary-dropdown ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
        background: #2f5471;
    }

    .primary-menu.nav-dark-dropdown ul.navbar-nav li {
        border-color: #444;
    }

    .primary-menu.nav-dark-dropdown ul.navbar-nav > li > a {
        color: #a3a2a2;
    }

    .primary-menu.nav-dark-dropdown ul.navbar-nav > li:hover > a {
        color: #fff;
    }

    .primary-menu.nav-primary-dropdown ul.navbar-nav li {
        border-color: rgba(250, 250, 250, 0.2);
    }

    .primary-menu.nav-primary-dropdown ul.navbar-nav > li > a {
        color: rgba(250, 250, 250, 0.8);
    }

    .primary-menu.nav-primary-dropdown ul.navbar-nav > li:hover > a {
        color: #fff;
    }
}

@media (min-width: 992px) {
    .navbar-toggler {
        display: none;
    }

    .primary-menu ul.navbar-nav > li + li {
        margin-left: 2px;
    }

    .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a:not(.btn) {
        margin-left: 5px;
    }

    .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a .arrow {
        right: -3px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .primary-menu ul.navbar-nav > li.dropdown > .dropdown-toggle .arrow {
        display: none;
    }

    .primary-menu ul.navbar-nav > li.dropdown-mega .sub-title:first-child {
        margin-top: 0px;
    }
}

@media (max-width: 991px) {
    /* Mobile Menu Button */
    .navbar-toggler {
        width: 25px;
        height: 30px;
        padding: 10px;
        margin: 18px 10px;
        position: relative;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        display: block;
    }

        .navbar-toggler span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: #1c6dc1;
            border-radius: 2px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
        }

            .navbar-toggler span:nth-child(1) {
                top: 6px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }

            .navbar-toggler span:nth-child(2) {
                top: 12px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }

            .navbar-toggler span:nth-child(3) {
                top: 18px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }

        .navbar-toggler.open span:nth-child(1) {
            top: 5px;
            left: 4px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .navbar-toggler.open span:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        .navbar-toggler.open span:nth-child(3) {
            top: 21px;
            left: 4px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

    #header .primary-menu {
        position: absolute;
        top: 99%;
        right: 0;
        left: 0;
        background: transparent;
        margin-top: 0px;
        z-index: 1000;
    }

        #header .primary-menu:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            width: 100vw;
            height: 100%;
            background: #fff;
            z-index: -1;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        }

        #header .primary-menu > div {
            overflow: hidden;
            overflow-y: auto;
            max-height: 65vh;
            margin: 18px 0;
        }

    .primary-menu ul.navbar-nav li {
        display: block;
        border-bottom: 1px solid #eee;
        margin: 0;
        padding: 0;
    }

        .primary-menu ul.navbar-nav li:last-child {
            border: none;
        }

        .primary-menu ul.navbar-nav li.dropdown > .dropdown-toggle > .arrow.open:after {
            -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

    .primary-menu ul.navbar-nav > li > a {
        height: auto;
        padding: 8px 0;
        position: relative;
        color: #196bc0 !important;
    }

    .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
        padding: 8px 0;
        position: relative;
    }

    .primary-menu ul.navbar-nav > li.dropdown:hover > a:after {
        content: none;
    }

    .primary-menu ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
        -webkit-transform: translate(-50%, -50%) rotate(134deg);
        transform: translate(-50%, -50%) rotate(134deg);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu {
        margin: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;
        padding: 0px 0px 0px 15px;
    }

        .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
            margin: 0;
        }

    .primary-menu ul.navbar-nav > li.dropdown-mega .dropdown-mega-content > .row > div {
        padding: 0px 15px;
    }

    .primary-menu ul.navbar-nav > li.dropdown-mega .sub-title {
        margin-top: 10px;
        display: block;
        padding: 0;
    }

    .primary-menu ul.navbar-nav > li.login-signup > a:before {
        content: none;
    }
}
/* Secondary Nav */
.secondary-nav.nav {
    padding-top: 12px;
    padding-bottom: 0px;
    padding-left: 8px;
}

    .secondary-nav.nav .nav-link {
        text-align: center;
        font-size: 13px;
        font-size: 0.8125rem;
        margin: 0 10px;
        padding: .6rem 15px;
        color: #8298af;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

        .secondary-nav.nav .nav-link:hover {
            color: #a6bcd3;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

    .secondary-nav.nav .nav-item:first-child .nav-link {
        margin-left: 0px;
    }

    .secondary-nav.nav .nav-link span {
        display: block;
        font-size: 30px;
        margin-bottom: 5px;
    }

    .secondary-nav.nav .nav-item .nav-link.active {
        background: #fff;
        color: #2f5471;
        border-radius: 4px 4px 0px 0px;
    }

    .secondary-nav.nav.alternate {
        margin-bottom: 10px;
    }

        .secondary-nav.nav.alternate .nav-link {
            padding: .3rem 15px;
        }

        .secondary-nav.nav.alternate .nav-item .nav-link.active {
            background-color: transparent;
            color: #fff;
            border-bottom: 3px solid #2f5471;
        }

@media (max-width: 1199px) {
    .secondary-nav.nav {
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
}
/* Page Header */
.page-header {
    margin: 0 0 30px 0;
    padding: 25px 0;
}

    .page-header h1 {
        font-weight: normal;
        font-size: 25px;
        margin: 0;
        padding: 5px 0;
    }

    .page-header .breadcrumb {
        background: none;
        margin: 0 0 8px 2px;
        padding: 0;
        position: relative;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

        .page-header .breadcrumb > li {
            display: inline-block;
            font-size: 0.85em;
            text-shadow: none;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

            .page-header .breadcrumb > li + li:before {
                color: inherit;
                opacity: 0.7;
                font-family: 'Font Awesome 5 Free';
                content: "\f105";
                padding: 0 7px 0 5px;
                font-weight: 900;
            }

            .page-header .breadcrumb > li a {
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

                .page-header .breadcrumb > li a:hover {
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }

    .page-header.page-header-text-light {
        color: #fff;
    }

        .page-header.page-header-text-light h1 {
            color: #fff;
        }

        .page-header.page-header-text-light .breadcrumb > li {
            color: rgba(250, 250, 250, 0.8);
        }

            .page-header.page-header-text-light .breadcrumb > li a {
                color: rgba(250, 250, 250, 0.8);
            }

                .page-header.page-header-text-light .breadcrumb > li a:hover {
                    color: #fff;
                }

    .page-header.page-header-text-dark h1 {
        color: #0c2f54;
    }

    .page-header.page-header-text-dark .breadcrumb > li {
        color: #707070;
    }

        .page-header.page-header-text-dark .breadcrumb > li a {
            color: #707070;
        }

            .page-header.page-header-text-dark .breadcrumb > li a:hover {
                color: #2f5471;
            }

    .page-header.page-header-dark {
        color: #fff;
    }

        .page-header.page-header-dark h1 {
            color: #fff;
        }

        .page-header.page-header-dark .breadcrumb > li {
            color: rgba(250, 250, 250, 0.8);
        }

            .page-header.page-header-dark .breadcrumb > li a {
                color: rgba(250, 250, 250, 0.8);
            }

                .page-header.page-header-dark .breadcrumb > li a:hover {
                    color: #fff;
                }

    .page-header.page-header-light h1 {
        color: #0c2f54;
    }

    .page-header.page-header-light .breadcrumb > li {
        color: #707070;
    }

        .page-header.page-header-light .breadcrumb > li a {
            color: #707070;
        }

            .page-header.page-header-light .breadcrumb > li a:hover {
                color: #2f5471;
            }

/* =================================== */
/*  Rechare & Bill Payment
/* =================================== */
/* View Plans Link */
.view-plans-link {
    position: absolute;
    right: 0px;
    z-index: 4;
    line-height: 50px;
    padding: 0 15px;
    font-size: 13px;
    text-decoration: underline;
}

/* Plans */
.plans {
    max-height: 380px;
    overflow: hidden;
    overflow-y: auto;
}

    .plans.page {
        max-height: 617px;
    }

    .plans .table td, .plans .table th {
        padding: 1.8rem;
    }

/* =================================== */
/*  Booking
/* =================================== */
/*==== Booking Page =====*/
.travellers-class {
    position: relative;
}

.travellers-class-input {
    cursor: pointer;
}

    .travellers-class-input[readonly] {
        background: #fff;
    }

.travellers-dropdown {
    position: absolute;
    display: none;
    -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.176);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.176);
    z-index: 11;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    min-width: 300px;
    width: 100%;
}

    .travellers-dropdown .qty .qty-spinner {
        background: none;
        border: none;
        pointer-events: none;
        text-align: center;
        padding: .2rem .2rem;
    }

    .travellers-dropdown .qty .btn {
        padding-top: .2rem;
        padding-bottom: .2rem;
        border-radius: 0.25rem !important;
    }

@media (max-width: 991px) {
    .travellers-dropdown {
        min-width: inherit;
    }
}

.icon-inside {
    position: absolute;
    right: 15px;
    top: calc(50% - 11px);
    pointer-events: none;
    font-size: 18px;
    font-size: 1.125rem;
    color: #c4c3c3;
    z-index: 3;
}

.form-control-sm + .icon-inside {
    font-size: 0.875rem !important;
    font-size: 14px;
    top: calc(50% - 13px);
}

.flight-list .flight-item, .train-list .train-item, .bus-list .bus-item {
    position: relative;
    border-bottom: 1px solid #e9e9e9;
}

    .flight-list .flight-item .flight-details .time-info small {
        line-height: 15px;
    }

.flight-list.round-trip .flight-item .company-info span, .flight-list.round-trip .flight-item .time-info small {
    line-height: 15px;
}

.round-trip-fare small {
    line-height: 14px;
}

.round-trip-fare .company-info img {
    min-width: 26px;
}

.confirm-details .company-info {
    line-height: 15px;
}

    .confirm-details .company-info img {
        min-width: 26px;
    }

.confirm-details .time-info small {
    line-height: 15px;
}

.promo-code {
    max-height: 150px;
    padding-left: 30px;
}

    .promo-code li {
        margin-bottom: 10px;
    }

.train-list .train-item .time-info small, .bus-list .bus-item .time-info small {
    line-height: 15px;
}

.date-available {
    border: 1px solid #dee2e6;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}

    .date-available li {
        flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
    }

        .date-available li + li {
            border-left: 1px solid #dee2e6;
        }

body {
    position: relative;
}

/*==== Hotels =====*/
.hotels-list .hotels-item {
    position: relative;
    margin-bottom: 1rem;
}

.hotels-reviews .hotels-reviews-score {
    background: #3FC299;
}

.hotels-amenities span {
    margin-right: .7rem;
    color: rgba(0, 0, 0, 0.4) !important;
}

    .hotels-amenities span.cf {
        color: rgba(0, 0, 0, 0.5) !important;
        border-color: rgba(0, 0, 0, 0.3) !important;
        line-height: normal;
    }

    .hotels-amenities span.disabled {
        color: rgba(0, 0, 0, 0.1) !important;
        cursor: not-allowed;
    }

.hotels-amenities-detail {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

    .hotels-amenities-detail span {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        margin-right: 10px;
        margin-bottom: 10px;
        color: #888;
    }

.review-tumb {
    width: 60px;
    height: 60px;
    line-height: 60px;
}

@media (max-width: 991px) {
    .flight-list .flight-item .flight-details .nav-tabs .nav-item .nav-link {
        font-size: 0.875rem;
        padding: .5rem 0.5rem;
    }

    .round-trip-fare .price {
        font-size: 18px !important;
    }

    .round-trip-fare .time-info span {
        font-size: 15px !important;
    }

    .confirm-details .date {
        font-size: 14px !important;
    }

    .confirm-details .trip-title .trip-arrow {
        font-size: 31px !important;
    }

    .confirm-details .trip-title .trip-place {
        font-size: 15px !important;
    }

    .train-list .train-item .time-info .duration, .bus-list .bus-item .time-info .duration {
        font-size: 13px !important;
    }

    .train-list .train-item .train-name, .bus-list .bus-item .operator-name {
        font-size: 15px !important;
    }
}

@media (max-width: 575px) {
    .flight-list:not(.round-trip) .flight-item .company-info span {
        line-height: 15px;
    }

    .flight-list:not(.round-trip) .flight-item .time-info span {
        font-size: 12px !important;
    }

    .flight-list:not(.round-trip) .flight-item .price {
        font-size: 13px !important;
    }

    .flight-list:not(.round-trip) .flight-item .btn-book .btn {
        width: 100%;
        margin-top: 5px;
    }

    .flight-list:not(.round-trip) .flight-item .flight-details .time-info span {
        font-size: 22px !important;
    }

    .flight-list .flight-item .flight-details .trip-title .trip-place {
        font-size: 15px !important;
    }

    .flight-list .flight-item .flight-details .trip-title .trip-arrow {
        font-size: 31px !important;
    }

    .round-trip-fare .price {
        font-size: 16px !important;
    }

    .round-trip-fare .time-info span {
        font-size: 13px !important;
    }

    .confirm-details .trip-title .trip-arrow {
        font-size: 22px !important;
    }

    .confirm-details .date {
        font-size: 13px !important;
    }
}
/* =================================== */
/*  Elements
/* =================================== */
/* Featured Box */
.featured-box {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

    .featured-box h3, .featured-box h4 {
        font-size: 1.25rem;
        margin-bottom: 10px;
        font-weight: 500;
    }

    .featured-box .featured-box-icon {
        display: inline-block;
        font-size: 35px;
        height: 45px;
        line-height: 45px;
        padding: 0;
        width: 45px;
        margin-top: 0;
        margin-bottom: 12px;
        color: #2f5471;
        border-radius: 0;
    }

    .featured-box.style-1, .featured-box.style-2, .featured-box.style-3 {
        padding-left: 50px;
        padding-top: 8px;
    }

        .featured-box.style-1 .featured-box-icon, .featured-box.style-2 .featured-box-icon, .featured-box.style-3 .featured-box-icon {
            position: absolute;
            top: 0;
            left: 0;
            margin-bottom: 0;
            font-size: 30px;
            -ms-flex-pack: center !important;
            justify-content: center !important;
            text-align: center;
        }

        .featured-box.style-2 p {
            margin-left: -50px;
        }

    .featured-box.style-3 {
        padding-left: 90px;
        padding-top: 0px;
    }

        .featured-box.style-3 .featured-box-icon {
            width: 70px;
            height: 70px;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

    .featured-box.style-4 {
        text-align: center;
    }

        .featured-box.style-4 .featured-box-icon {
            margin: 0 auto 24px;
            margin: 0 auto 1.5rem;
            width: 110px;
            height: 110px;
            text-align: center;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
        }

            .featured-box.style-4 .featured-box-icon i.fa, .featured-box.style-4 .featured-box-icon i.fas, .featured-box.style-4 .featured-box-icon i.far, .featured-box.style-4 .featured-box-icon i.fal, .featured-box.style-4 .featured-box-icon i.fab {
                font-size: 46.4px;
                font-size: 2.9rem;
                margin: 0 auto;
            }

/* Team */
.team {
    text-align: center;
    margin-bottom: 15px;
    padding: 15px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

    .team:hover {
        -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .team img {
        margin-bottom: 20px;
    }

    .team h3 {
        font-size: 18px;
        margin-bottom: 0px;
    }

    .team p {
        margin-bottom: 0.5rem;
    }

/* Accordion & Toggle */
.accordion .card {
    border: none;
    margin-bottom: 8px;
    margin-bottom: 0.5rem;
    background-color: transparent;
}

.accordion .card-header {
    padding: 0;
    border: none;
    background: none;
}

    .accordion .card-header a {
        font-size: 14px;
        padding: 1rem 1.25rem 1rem 2.25rem;
        display: block;
        border-radius: 4px;
        position: relative;
    }

.accordion:not(.accordion-alternate) .card-header a {
    background: #2f5471;
    color: #fff;
}

    .accordion:not(.accordion-alternate) .card-header a.collapsed {
        background-color: #f1f2f4;
        color: #535b61;
    }

.accordion .card-header a:before {
    position: absolute;
    content: " ";
    left: 20px;
    top: calc(50% + 2px);
    width: 8px;
    height: 8px;
    border-color: #CCC;
    border-top: 2px solid;
    border-right: 2px solid;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.accordion .card-header a.collapsed:before {
    top: calc(50% - 2px);
    -webkit-transform: translate(-50%, -50%) rotate(135deg);
    transform: translate(-50%, -50%) rotate(135deg);
}

.accordion .card-body {
    line-height: 26px;
    padding: 1rem 0 1rem 2.25rem;
}

.accordion.accordion-alternate .card {
    margin: 0;
}

.accordion.accordion-alternate .card-header a {
    padding-left: 1.25rem;
    border-top: 1px solid #e4e9ec;
    border-radius: 0px;
}

.accordion.accordion-alternate .card:first-of-type .card-header a {
    border-top: 0px;
}

.accordion.accordion-alternate .card-header a:before {
    left: 5px;
}

.accordion.accordion-alternate .card-header a.collapsed {
    color: #535b61;
}

.accordion.accordion-alternate .card-body {
    padding: 0rem 0 1rem 1.25rem;
}

.accordion.toggle .card-header a:before {
    content: "-";
    border: none;
    font-size: 20px;
    height: auto;
    top: 50%;
    width: auto;
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
}

.accordion.toggle .card-header a.collapsed:before {
    content: "+";
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
}

.accordion.accordion-alternate.style-2 .card-header a {
    padding-left: 0px;
}

    .accordion.accordion-alternate.style-2 .card-header a:before {
        right: 2px;
        left: auto;
        -webkit-transform: translate(-50%, -50%) rotate(135deg);
        transform: translate(-50%, -50%) rotate(135deg);
        top: 50%;
    }

    .accordion.accordion-alternate.style-2 .card-header a.collapsed:before {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
    }

.accordion.accordion-alternate.style-2 .card-body {
    padding-left: 0px;
}

.accordion.accordion-alternate.popularRoutes .card-header .nav {
    margin-top: 3px;
}

    .accordion.accordion-alternate.popularRoutes .card-header .nav a {
        font-size: 14px;
    }

.accordion.accordion-alternate.popularRoutes .card-header a {
    padding: 0px 8px 0px 0px;
    border: none;
    font-size: inherit;
}

    .accordion.accordion-alternate.popularRoutes .card-header a:before {
        content: none;
    }

.accordion.accordion-alternate.popularRoutes .card-header h5 {
    cursor: pointer;
}

    .accordion.accordion-alternate.popularRoutes .card-header h5:before {
        position: absolute;
        content: " ";
        right: 0px;
        top: 24px;
        width: 10px;
        height: 10px;
        opacity: 0.6;
        border-top: 2px solid;
        border-right: 2px solid;
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .accordion.accordion-alternate.popularRoutes .card-header h5.collapsed:before {
        top: 24px;
        -webkit-transform: translate(-50%, -50%) rotate(135deg);
        transform: translate(-50%, -50%) rotate(135deg);
    }

.accordion.accordion-alternate.popularRoutes .card-body {
    padding: 0;
}

.accordion.accordion-alternate.popularRoutes .card {
    border-bottom: 2px solid #e4e9ec;
    padding: 15px 0px;
}

.accordion.accordion-alternate.popularRoutes .routes-list {
    margin: 1rem 0px 0px 0px;
    padding: 0px;
    list-style: none;
}

    .accordion.accordion-alternate.popularRoutes .routes-list a {
        color: inherit;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

        .accordion.accordion-alternate.popularRoutes .routes-list a:hover {
            color: #2f5471;
            text-decoration: underline;
        }

/* Tabs */
.nav-tabs {
    border-bottom: 1px solid #d7dee3;
}

    .nav-tabs .nav-item .nav-link {
        border: 0;
        background: transparent;
        font-size: 1rem;
        position: relative;
        border-radius: 0;
        color: #7b8084;
        white-space: nowrap !important;
    }

        .nav-tabs .nav-item .nav-link.active {
            color: #0c2f55;
        }

            .nav-tabs .nav-item .nav-link.active:after {
                height: 2px;
                width: 100%;
                content: ' ';
                background: #2f5471;
                display: block;
                position: absolute;
                bottom: -3px;
                left: 0;
                -webkit-transform: translateY(-3px);
                transform: translateY(-3px);
            }

    .nav-tabs.flex-column {
        border-right: 1px solid #d7dee3;
        border-bottom: 0px;
        padding: 1.5rem 0;
    }

        .nav-tabs.flex-column .nav-item .nav-link {
            border: 1px solid #d7dee3;
            border-right: 0px;
            background-color: #f6f7f8;
            font-size: 14px;
            padding: 0.75rem 1rem;
            color: #535b61;
        }

        .nav-tabs.flex-column .nav-item:first-of-type .nav-link {
            border-top-left-radius: 4px;
        }

        .nav-tabs.flex-column .nav-item:last-of-type .nav-link {
            border-bottom-left-radius: 4px;
        }

        .nav-tabs.flex-column .nav-item .nav-link.active {
            background-color: transparent;
            color: #2f5471;
        }

            .nav-tabs.flex-column .nav-item .nav-link.active:after {
                height: 100%;
                width: 2px;
                background: #fff;
                right: -1px;
                left: auto;
            }

    .nav-tabs.style-2 {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px 4px 0px 0px;
        border: 0px;
    }

        .nav-tabs.style-2 .nav-item {
            margin-bottom: 0px;
        }

            .nav-tabs.style-2 .nav-item .nav-link {
                color: #fff;
                font-size: 13px;
                padding: 0.7rem 1rem;
                text-transform: uppercase;
            }

                .nav-tabs.style-2 .nav-item .nav-link:hover {
                    background: rgba(250, 250, 250, 0.2);
                }

                    .nav-tabs.style-2 .nav-item .nav-link.active, .nav-tabs.style-2 .nav-item .nav-link:hover.active {
                        background: #2f5471;
                    }

                        .nav-tabs.style-2 .nav-item .nav-link.active:after {
                            content: none;
                        }

    .nav-tabs.style-3 {
        border: none;
        margin-bottom: 8px;
    }

        .nav-tabs.style-3.border-bottom {
            border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
        }

    .nav-tabs.style-4.border-bottom {
        border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
    }

    .nav-tabs.style-3 .nav-item .nav-link {
        color: #8298af;
        margin: 0 10px;
        padding: 0.6rem 0.9375rem;
        text-align: center;
        font-size: 13px;
        font-size: 0.8125rem;
    }

    .nav-tabs.style-3 .nav-item:first-child .nav-link {
        margin-left: 0px;
    }

    .nav-tabs.style-3 .nav-item .nav-link.active {
        color: #fff;
    }

    .nav-tabs.style-2 .nav-item .nav-link:hover.active {
        color: #fff;
    }

    .nav-tabs.style-3 .nav-item .nav-link:hover {
        color: #a6bcd3;
    }

    .nav-tabs.style-3 .nav-item .nav-link.active:after {
        height: 3px;
    }

    .nav-tabs.style-3 .nav-item .nav-link span {
        display: block;
        font-size: 30px;
        margin-bottom: 5px;
    }

    .nav-tabs.style-4 {
        border: none;
    }

        .nav-tabs.style-4 .nav-item {
            margin-right: 20px;
        }

            .nav-tabs.style-4 .nav-item .nav-link {
                color: #fff;
                opacity: 0.65;
                filter: alpha(opacity=65);
                text-align: center;
                font-size: 20px;
                padding-left: 0px;
                padding-right: 0px;
                padding-bottom: .7rem;
                font-weight: 500;
            }

                .nav-tabs.style-4 .nav-item .nav-link.active, .nav-tabs.style-4 .nav-item .nav-link:hover.active {
                    color: #fff;
                    opacity: 1;
                    filter: alpha(opacity=100);
                }

                    .nav-tabs.style-4 .nav-item .nav-link.active::after {
                        content: none;
                    }

    .nav-tabs .nav-item {
        margin-bottom: 0px;
    }

    .nav-tabs:not(.flex-column) {
        border-bottom: 1px solid #d7dee3;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }

@media (max-width: 575px) {
    .nav-tabs .nav-item .nav-link {
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 10px;
        font-size: 0.875rem;
    }
}

@media (min-width: 992px) {
    .search-input-2 .form-control {
        border-radius: 0px;
    }

    .search-input-2 .custom-select:not(.custom-select-sm) {
        border-radius: 0px;
        height: calc(3.05rem);
    }

    .search-input-2 .btn {
        border-radius: 0px;
    }

    .search-input-2 .form-group:first-child .form-control, .search-input-2 .form-group:first-child .custom-select {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .search-input-2 .form-group:last-child .btn {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .search-input-2 .form-control:focus, .search-input-2 .custom-select:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .search-input-2 .form-group .form-control, .search-input-2 .custom-select {
        border-left: none;
        border-top: none;
        border-bottom: none;
    }

    /* CSS hack for Chrome */
}

@media screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 0) {
    .search-input-2 .custom-select:not(.custom-select-sm) {
        height: calc(3.00rem);
    }

    .search-input-2 .btn {
        line-height: inherit;
    }
}

@media (min-width: 992px) {
    /*  CSS hack for Firfox */
    @-moz-document url-prefix() {
        .search-input-2 .custom-select:not(.custom-select-sm) {
            height: calc(3.05rem);
        }

        .search-input-2 .btn {
            line-height: 1.4;
        }
    }
}

.search-input-line .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgba(250, 250, 250, 0.5);
    border-radius: 0px;
    padding-left: 0px !important;
    color: #ccc;
}

    .search-input-line .form-control::-webkit-input-placeholder {
        color: #ccc;
    }

    .search-input-line .form-control:-moz-placeholder {
        /* FF 4-18 */
        color: #ccc;
    }

    .search-input-line .form-control::-moz-placeholder {
        /* FF 19+ */
        color: #ccc;
    }

    .search-input-line .form-control:-ms-input-placeholder, .search-input-line .form-control::-ms-input-placeholder {
        /* IE 10+ */
        color: #ccc;
    }

.search-input-line .custom-select {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgba(250, 250, 250, 0.5);
    border-radius: 0px;
    padding-left: 0px;
    color: #ccc;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(250,250,250,0.6)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 13px 15px;
}

    .search-input-line .form-control:focus, .search-input-line .custom-select:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

.search-input-line .form-control:not(output):-moz-ui-invalid:not(:focus), .search-input-line .form-control:not(output):-moz-ui-invalid:-moz-focusring:not(:focus) {
    border-bottom: 2px solid #b00708;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.search-input-line .icon-inside {
    color: #999;
}

.search-input-line select option {
    color: #333;
}

.search-input-line .travellers-dropdown input {
    color: #666;
}

/* Easy Responsive Tab Accordion */
.resp-htabs ul.resp-tabs-list {
    margin: 0px;
    padding: 0px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    border-bottom: 1px solid #d7dee3;
}

.resp-tabs-list li {
    padding: .5rem 1rem !important;
    margin: 0;
    list-style: none;
    cursor: pointer;
}

h2.resp-accordion {
    cursor: pointer;
    padding: 5px;
    display: none;
}

.resp-tab-content {
    display: none;
    padding: 15px;
}

.resp-tab-active {
    margin-bottom: -1px !important;
    border-bottom: 2px solid #2f5471;
}

.resp-content-active, .resp-accordion-active {
    display: block;
}

h2.resp-accordion {
    font-size: 16px;
    color: #777;
    border: 1px solid #e4e9ec;
    border-top: 0px solid #e4e9ec;
    margin: 0px;
    padding: 1rem 1.25rem;
}

h2.resp-tab-active {
    border-bottom: 0px solid #e4e9ec !important;
    margin-bottom: 0px !important;
    padding: 1rem 1.25rem !important;
}

h2.resp-tab-title:last-child {
    border-bottom: 12px solid #e4e9ec !important;
    background: blue;
}

/* Easy Responsive Vertical tabs */
.resp-vtabs ul.resp-tabs-list {
    margin: 0;
    padding: 0;
}

.resp-vtabs .resp-tabs-list li {
    display: block;
    padding: 15px 15px !important;
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    color: #999;
    border: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

    .resp-vtabs .resp-tabs-list li:hover {
        color: #555;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .resp-vtabs .resp-tabs-list li span {
        font-size: 20px;
        text-align: center;
        width: 30px;
        display: inline-block;
        float: left;
        margin-right: 15px;
    }

h2.resp-accordion span {
    font-size: 20px;
    text-align: center;
    width: 30px;
    display: inline-block;
    float: left;
    margin-right: 15px;
}

.resp-vtabs .resp-tabs-container {
    padding: 0px;
}

.resp-vtabs .resp-tab-content {
    border: none;
}

.resp-vtabs li.resp-tab-active, .resp-vtabs li.resp-tab-active:hover {
    color: #2f5471;
    -webkit-box-shadow: -5px 0px 24px -18px rgba(0, 0, 0, 0.4);
    box-shadow: -5px 0px 24px -18px rgba(0, 0, 0, 0.4);
    border-radius: 4px 0px 0px 4px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    margin-right: -1px !important;
    margin-bottom: 0px !important;
}

.resp-arrow {
    width: 0;
    height: 0;
    float: right;
    margin-top: 6px;
    border-color: #000;
    border-top: 1px solid;
    border-right: 1px solid;
    width: 9px;
    height: 9px;
    -webkit-transform: translate(-50%, -50%) rotate(135deg);
    transform: translate(-50%, -50%) rotate(135deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

h2.resp-tab-active {
    background: #f1f2f4 !important;
    color: #535b61;
}

    h2.resp-tab-active i.resp-arrow {
        margin-top: 10px;
        transform: translate(-50%, -50%) rotate(-45deg);
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

/* Accordion Styles */
.resp-easy-accordion h2.resp-accordion {
    display: block;
}

.resp-easy-accordion .resp-tab-content {
    border: 1px solid #e4e9ec;
}

    .resp-easy-accordion .resp-tab-content:last-child {
        border-bottom: 1px solid #e4e9ec !important;
    }

.resp-jfit {
    width: 100%;
    margin: 0px;
}

.resp-tab-content-active {
    display: block;
}

h2.resp-accordion:first-child {
    border-top: 1px solid #e4e9ec !important;
}

@media only screen and (max-width: 768px) {
    ul.resp-tabs-list {
        display: none !important;
    }

    h2.resp-accordion {
        display: block;
    }

    .resp-vtabs .resp-tab-content, .resp-htabs .resp-tab-content {
        border: 1px solid #e4e9ec;
    }

    .resp-vtabs .resp-tabs-container {
        border: none;
        float: none;
        width: 100%;
        min-height: initial;
        clear: none;
    }

    .resp-accordion-closed {
        display: none !important;
    }

    .resp-vtabs .resp-tab-content:last-child {
        border-bottom: 1px solid #e4e9ec !important;
    }
}
/* Custom Background */
.hero-wrap {
    position: relative;
}

    .hero-wrap .hero-mask, .hero-wrap .hero-bg, .hero-wrap .hero-bg-slideshow {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .hero-wrap .hero-mask {
        z-index: 1;
    }

    .hero-wrap .hero-content {
        position: relative;
        z-index: 2;
    }

    .hero-wrap .hero-bg-slideshow {
        z-index: 0;
    }

    .hero-wrap .hero-bg {
        z-index: 0;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        transition: background-image 300ms ease-in 200ms;
    }

    .hero-wrap .hero-bg-slideshow .hero-bg {
        background-attachment: inherit;
    }

    .hero-wrap .hero-bg-slideshow.owl-carousel .owl-stage-outer, .hero-wrap .hero-bg-slideshow.owl-carousel .owl-stage, .hero-wrap .hero-bg-slideshow.owl-carousel .owl-item {
        height: 100%;
    }

/* Brands Grid */
.brands-grid {
    overflow: hidden;
}

    .brands-grid > .row > div {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .brands-grid.separator-border > .row > div:after, .brands-grid.separator-border > .row > div:before {
        content: '';
        position: absolute;
    }

    .brands-grid.separator-border > .row > div:after {
        width: 100%;
        height: 0;
        top: auto;
        left: 0;
        bottom: -1px;
        border-bottom: 1px dotted #e0dede;
    }

    .brands-grid.separator-border > .row > div:before {
        height: 100%;
        top: 0;
        left: -1px;
        border-left: 1px dotted #e0dede;
    }

    .brands-grid > .row > div a {
        opacity: 0.7;
        color: #444;
    }

        .brands-grid > .row > div a:hover {
            opacity: 1;
            color: #2f5471;
        }

/* Banner */
.banner .item {
    position: relative;
}

    .banner .item img {
        vertical-align: middle;
    }

.banner .caption {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    padding: 15px 20px;
}

    .banner .caption h2 {
        font-size: 19px;
        color: #fff;
    }

    .banner .caption p {
        color: rgba(250, 250, 250, 0.8);
        margin-bottom: 0px;
    }

.banner .rounded .banner-mask, .banner .rounded img {
    border-radius: .25rem;
}

.banner .banner-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    backface-visibility: hidden;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.6));
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.6));
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.01), black);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.01), black);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.01), black);
    opacity: 0.7;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.banner .item:hover .banner-mask {
    opacity: 0.95;
    filter: alpha(opacity=99);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/* Simple ul */
.simple-ul > li {
    position: relative;
    list-style-type: none;
    line-height: 24px;
}

    .simple-ul > li:after {
        content: " ";
        position: absolute;
        top: 12px;
        left: -15px;
        border-color: #000;
        border-top: 1px solid;
        border-right: 1px solid;
        width: 6px;
        height: 6px;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
    }

/* =================================== */
/*  Footer Styles
/* =================================== */
#footer {
    color: #fff;
    padding: 0px 0px 35px 0px;
    padding: 0 0 2rem 0;
    /*  margin-top: 30px;*/
    background: #2f5471;
}

    #footer .footer-copyright {
        border-top: 1px solid #e6e9ec;
        padding: 32px 0px 0px;
        margin-top: 2rem;
        margin-top: 32px;
        text-align: center;
    }

        #footer .footer-copyright .nav .nav-item {
            display: inline-block;
            line-height: 12px;
            margin: 0;
            padding: 0 10px;
        }

            #footer .footer-copyright .nav .nav-item .nav-link {
                padding-left: 0;
                padding-right: 0;
                color: #fff;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

                #footer .footer-copyright .nav .nav-item .nav-link:focus {
                    color: #2f5471;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }

        #footer .footer-copyright .nav .nav-link:hover {
            color: #fff;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        #footer .footer-copyright .copyright-text {
            color: #fff;
            margin: 12px 0 0 0;
            padding: 0;
        }

/* Payments Images */
.payments-types {
    margin: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
}

    .payments-types li {
        margin: 0px 10px 8px 0px;
    }

        .payments-types li a {
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            opacity: 1;
        }

        .payments-types li img {
            display: flex;
        }

        .payments-types li:hover a {
            opacity: 0.8;
        }

/* Newsleter */
.newsletter .form-control {
    height: 38px !important;
    font-size: 14px;
}

.newsletter .btn {
    height: 38px;
    padding-top: 0;
    padding-bottom: 0px;
    font-size: 14px;
    background: #2f5471;
    border-color: #2f5471;
}

    .newsletter .btn:hover {
        background: #415b78;
    }

/* Social Icons */
.social-icons {
    margin: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
}

    .social-icons li {
        margin: 0px 2px 4px;
        padding: 0;
        border-radius: 100%;
        overflow: visible;
    }

        .social-icons li:last-child {
            margin-right: 0px;
        }

        .social-icons li a {
            background: #d4d4d4;
            border-radius: 100%;
            display: block;
            height: 34px;
            line-height: 34px;
            width: 34px;
            font-size: 16px;
            text-align: center;
            color: #fff;
            text-decoration: none;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        .social-icons li i {
            line-height: inherit;
        }

    .social-icons.social-icons-sm li a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }

    .social-icons.social-icons-lg li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
    }

    .social-icons.social-icons-dark li a {
        background: #555;
    }

    .social-icons li:hover a {
        background: #171717;
        color: #FFF;
    }

    .social-icons li:hover.social-icons-twitter a, .social-icons.social-icons-colored li.social-icons-twitter a {
        background: #00ACEE;
    }

    .social-icons li:hover.social-icons-facebook a, .social-icons.social-icons-colored li.social-icons-facebook a {
        background: #3B5998;
    }

    .social-icons li:hover.social-icons-linkedin a, .social-icons.social-icons-colored li.social-icons-linkedin a {
        background: #0E76A8;
    }

    .social-icons li:hover.social-icons-rss a, .social-icons.social-icons-colored li.social-icons-rss a {
        background: #EE802F;
    }

    .social-icons li:hover.social-icons-google a, .social-icons.social-icons-colored li.social-icons-google a {
        background: #DD4B39;
    }

    .social-icons li:hover.social-icons-pinterest a, .social-icons.social-icons-colored li.social-icons-pinterest a {
        background: #cc2127;
    }

    .social-icons li:hover.social-icons-youtube a, .social-icons.social-icons-colored li.social-icons-youtube a {
        background: #C4302B;
    }

    .social-icons li:hover.social-icons-instagram a, .social-icons.social-icons-colored li.social-icons-instagram a {
        background: #3F729B;
    }

    .social-icons li:hover.social-icons-skype a, .social-icons.social-icons-colored li.social-icons-skype a {
        background: #00AFF0;
    }

    .social-icons li:hover.social-icons-email a, .social-icons.social-icons-colored li.social-icons-email a {
        background: #6567A5;
    }

    .social-icons li:hover.social-icons-vk a, .social-icons.social-icons-colored li.social-icons-vk a {
        background: #2B587A;
    }

    .social-icons li:hover.social-icons-xing a, .social-icons.social-icons-colored li.social-icons-xing a {
        background: #126567;
    }

    .social-icons li:hover.social-icons-tumblr a, .social-icons.social-icons-colored li.social-icons-tumblr a {
        background: #34526F;
    }

    .social-icons li:hover.social-icons-reddit a, .social-icons.social-icons-colored li.social-icons-reddit a {
        background: #C6C6C6;
    }

    .social-icons li:hover.social-icons-delicious a, .social-icons.social-icons-colored li.social-icons-delicious a {
        background: #205CC0;
    }

    .social-icons li:hover.social-icons-stumbleupon a, .social-icons.social-icons-colored li.social-icons-stumbleupon a {
        background: #F74425;
    }

    .social-icons li:hover.social-icons-digg a, .social-icons.social-icons-colored li.social-icons-digg a {
        background: #191919;
    }

    .social-icons li:hover.social-icons-blogger a, .social-icons.social-icons-colored li.social-icons-blogger a {
        background: #FC4F08;
    }

    .social-icons li:hover.social-icons-flickr a, .social-icons.social-icons-colored li.social-icons-flickr a {
        background: #FF0084;
    }

    .social-icons li:hover.social-icons-vimeo a, .social-icons.social-icons-colored li.social-icons-vimeo a {
        background: #86C9EF;
    }

    .social-icons li:hover.social-icons-yahoo a, .social-icons.social-icons-colored li.social-icons-yahoo a {
        background: #720E9E;
    }

    .social-icons li:hover.social-icons-googleplay a, .social-icons.social-icons-colored li.social-icons-googleplay a {
        background: #DD4B39;
    }

    .social-icons li:hover.social-icons-apple a, .social-icons.social-icons-colored li.social-icons-apple a {
        background: #000;
    }

    .social-icons.social-icons-colored li:hover a {
        background: #d4d4d4;
    }

/* Login/Signup Modal Dialog */
#login-signup .modal-dialog,
#login-signup-page {
    max-width: 430px;
}

/* Back to Top */
#back-to-top {
    display: none;
    position: fixed;
    z-index: 1030;
    bottom: 8px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    color: #fff;
    font-size: 18px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

    #back-to-top:hover {
        background: #2f5471;
        -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

@media (max-width: 575px) {
    #back-to-top {
        z-index: 1029;
    }
}
/* =================================== */
/*  Extras
/* =================================== */
/* Bootstrap Specific */
.form-control, .custom-select {
    border-color: #d5d3d3;
    font-size: 15px;
    color: #777;
}

    .form-control:not(.form-control-sm) {
        padding: .810rem .96rem;
        height: inherit;
    }

.form-control-sm {
    font-size: 14px;
}

select.form-control:not([size]):not([multiple]):not(.form-control-sm) {
    height: auto;
    padding-top: .700rem;
    padding-bottom: .700rem;
}

.custom-select:not(.custom-select-sm) {
    height: calc(3.05rem + 2px);
    padding-top: .700rem;
    padding-bottom: .700rem;
}

.col-form-label-sm {
    font-size: 13px;
}

.custom-select-sm {
    padding-left: 5px !important;
    font-size: 14px;
}

.form-control:focus {
    -webkit-box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
    box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}

    .form-control:focus[readonly] {
        box-shadow: none;
    }

.input-group-text {
    border-color: #d5d3d3;
    color: #777;
}

.form-control::-webkit-input-placeholder {
    color: #777;
}

.form-control:-moz-placeholder {
    /* FF 4-18 */
    color: #777;
}

.form-control::-moz-placeholder {
    /* FF 19+ */
    color: #777;
}

.form-control:-ms-input-placeholder, .form-control::-ms-input-placeholder {
    /* IE 10+ */
    color: #777;
}

.btn {
    padding: .750rem 2.5rem;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.btn-sm {
    padding: 0.5rem 1rem;
}

.btn:not(.btn-link) {
    -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

    .btn:not(.btn-link):hover {
        -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

.input-group-append .btn, .input-group-prepend .btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 0.75rem;
        padding-right: 0.75rem;
}

    .input-group-append .btn:hover, .input-group-prepend .btn:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

@media (max-width: 575px) {
    .btn:not(.btn-sm) {
        padding: .810rem 1rem;
    }

    .input-group > .input-group-append > .btn, .input-group > .input-group-prepend > .btn {
        padding: 0 0.75rem;
    }
}

.bg-primary, .badge-primary {
    background-color: #2f5471 !important;
}

.bg-secondary {
    background-color:#2f5471 !important;
}

.text-primary {
    color: #2f5471 !important;
}

.text-secondary {
    color: #0c2f55 !important;
}

.text-primary {
    color: #2f5471;
}

.border-primary {
    border-color: #2f5471;
}

.border-secondary {
    border-color: #0c2f55 !important;
}

.btn-primary {
    background: #2f5471;
    border-color: #2f5471;
}

    .btn-primary:hover {
        background-color: #173a55;
        border-color: #173a55;
    }

.btn-secondary {
    background-color: #0c2f55;
    border-color: #0c2f55;
}

.btn-outline-primary {
    color: #2f5471;
    border-color: #2f5471;
}

    .btn-outline-primary:hover {
        background: #2f5471;
        border-color: #2f5471;
        color: #fff;
    }

.btn-outline-secondary {
    color: #0c2f55;
    border-color: #0c2f55;
}

    .btn-outline-secondary:hover {
        background-color: #0c2f55;
        border-color: #0c2f55;
        color: #fff;
    }

.page-item.active .page-link,
.progress-bar,
.nav-pills .nav-link.active, .nav-pills .show > .nav-link,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
    background: #2f5471;
}

.list-group-item.active {
    background: #2f5471;
    border-color: #2f5471;
}

.page-link {
    color: #2f5471;
}

    .page-link:hover {
        color: #173a55;
    }

/* Slider Range (jQuery UI) */
.ui-slider-horizontal {
    height: .2em;
    margin-left: 11px;
    margin-right: 11px;
}

    .ui-slider-horizontal .ui-slider-handle {
        top: -0.7em;
        margin-left: -.7em;
        border-radius: 100%;
        background: #fff;
        width: 1.5em;
        height: 1.5em;
    }

.ui-widget.ui-widget-content {
    border: none;
    background: #eee;
    margin-bottom: 15px;
}

.ui-widget-header {
    background: #2f5471;
}

/* Steps Progress bar */
.widget-steps > .step {
    padding: 0;
    position: relative;
}

    .widget-steps > .step .step-name {
        font-size: 16px;
        margin-bottom: 5px;
        text-align: center;
    }

    .widget-steps > .step > .step-dot {
        position: absolute;
        width: 30px;
        height: 30px;
        display: block;
        background: #fff;
        border: 1px solid #28a745;
        top: 45px;
        left: 50%;
        margin-top: -15px;
        margin-left: -15px;
        border-radius: 50%;
    }

        .widget-steps > .step > .step-dot:after {
            width: 10px;
            height: 10px;
            border-radius: 50px;
            position: absolute;
            top: 9px;
            left: 9px;
        }

    .widget-steps > .step.complete > .step-dot:after {
        content: '\f00c';
        font-weight: 900;
        color: #28a745;
        font-family: "Font Awesome 5 Free";
        top: 3px;
        left: 7px;
    }

    .widget-steps > .step.active > .step-dot:after {
        background: #28a745;
        content: '';
    }

    .widget-steps > .step > .progress {
        position: relative;
        background: #bbb;
        border-radius: 0px;
        height: 1px;
        box-shadow: none;
        margin: 22px 0;
    }

        .widget-steps > .step > .progress > .progress-bar {
            width: 0px;
            box-shadow: none;
            background: #28a745;
        }

    .widget-steps > .step.complete > .progress > .progress-bar {
        width: 100%;
    }

    .widget-steps > .step.active > .progress > .progress-bar {
        width: 50%;
    }

    .widget-steps > .step:first-child.active > .progress > .progress-bar {
        width: 0%;
    }

    .widget-steps > .step:last-child.active > .progress > .progress-bar {
        width: 100%;
    }

    .widget-steps > .step.disabled > .step-dot {
        border-color: #bbb;
    }

    .widget-steps > .step:first-child > .progress {
        left: 50%;
        width: 50%;
    }

    .widget-steps > .step:last-child > .progress {
        width: 50%;
    }

    .widget-steps > .step.disabled a.step-dot {
        pointer-events: none;
    }

@media (max-width: 575.98px) {
    .widget-steps > .step .step-name {
        font-size: 14px;
    }
}

html[dir="rtl"] .widget-steps > .step > .step-dot {
    top: 45px;
    right: 50%;
    left: auto;
    margin-left: 0;
    margin-right: -15px;
}

    html[dir="rtl"] .widget-steps > .step > .step-dot:after {
        left: 0px;
        right: 9px;
    }

html[dir="rtl"] .widget-steps > .step.complete > .step-dot:after {
    left: 0px;
    right: 7px;
}

html[dir="rtl"] .widget-steps > .step:first-child > .progress {
    left: auto;
    right: 50%;
}

.calling a span {
    display: block;
    font-size: 13px;
    text-align: right;
}

@media (max-width:767px) {
    #cookiePopup {
        width: 100%;
    }
}

#cookiePopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

#cookiePopup {
    background-color: #eee;
    font-size: 14px;
    box-shadow: -6em 35em 47em 59em rgba(0, 0, 0, 0.76);
    text-align: justify;
    line-height: 1.8em;
    padding: 36px 14.4px 0px 14.4px;
    border-radius: 6px;
}

    #cookiePopup p {
        text-align: center;
    }

    #cookiePopup button {
        background-color: #01353c;
        border: none;
        color: #ffffff;
        font-size: 1.2em;
        padding: 1em 1.4em;
        display: block;
        position: relative;
        margin: auto;
        border-radius: 5px;
    }

.deleteMeetingClose {
    font-size: 2.5em;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
}


input[type="number"] {
    -moz-appearance: textfield !important;
}

#footer p {
    color: #fff;
}

/*.MuiDialog-root p, .MuiDialog-root p a {
    color: #000 !important;
}*/

#footer h6 {
    color: #fff;
}

#footer .section.bg-light, #footer .section.bg-light p {
    color: #000;
}

#cookiePopup p.h4 {
    color: #000;
}
    #cookiePopup p.h2 a
    {
        color:#000 !important;
    }
